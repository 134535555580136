import Image from "next/image";

import Heading from "@/components/heading/heading"

import TitleArrow from "@/public/svg/common/title/title-arrow.svg";

interface TitleProps {
    title: string;
}

const Title = (props: TitleProps) => {
    const { title } = props;
    return (
        <div className="title flex flex-col items-center justify-center text-center">
            <Heading as="h2">{title}</Heading>
            <Image loading="lazy"
                src={TitleArrow}
                alt="TitleArrow"
                className="title-arrow"
                
            />
        </div>
    );
};

export default Title;
