import(/* webpackMode: "eager" */ "/app/components/button/button.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/destinationPriceCard/destinationPriceCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/heading/heading.scss");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/public/png/common/enquiry/common-cta-footer.png");
;
import(/* webpackMode: "eager" */ "/app/public/png/not-found/404.svg");
;
import(/* webpackMode: "eager" */ "/app/public/svg/common/social-icons/whatsapp.svg");
;
import(/* webpackMode: "eager" */ "/app/sections/common/enquiry/enquiry.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/sections/common/footer/contact.tsx");
;
import(/* webpackMode: "eager" */ "/app/styles/not-found/notfound.scss");
;
import(/* webpackMode: "eager" */ "/app/sections/common/footer/footer.scss");
